

















































































































import { createComponent, reactive, ref, computed } from '@vue/composition-api'
import moment from 'moment'
import { firebaseApp } from '@/plugins/firestore'
import PhoneHeader from '@/components/phone-header.vue'
import NewMessage from '@/components/new_message.vue'

type User = {
  name: string
  icon: string
  currentTime: string
}

type NavigationItem = {
  title: string
  icon: string
  items: { title: string; role: string }[]
}

export default createComponent({
  components: {
    'cti-phone-header': PhoneHeader,
    'cti-new-message': NewMessage
  },
  setup(_props, context) {
    const isLoggedIn = context.root.$auth.loggedIn
    if (!isLoggedIn) {
      context.root.$router.push({
        path: '/login/'
      })
    }
    const user = reactive<User>({
      name: context.root.$auth.user ? context.root.$auth.user.username : '',
      icon: '../assets/img/operator.png', // Icon - navigation - Operator WHITE
      currentTime: moment().format('HH:mm')
    })
    setInterval(() => {
      user.currentTime = moment().format('HH:mm')
    }, 1000)
    const navItems = ref<NavigationItem[]>([
      {
        title: '運行状況',
        icon: 'mdi-calendar',
        items: [
          { title: '車両運行状況', link: '/overview/buses/' },
          { title: 'ユーザー利用状況', link: '/overview/passengers/' },
          { title: '予約状況', link: '/overview/reservations/' },
          { title: 'ドライバー日報', link: '/overview/daily_report/' }
        ]
      },
      {
        title: '管理機能',
        icon: 'mdi-account',
        items: [
          { title: 'ユーザー管理', link: '/administration/users/' },
          { title: 'ドライバー管理', link: '/administration/drivers/' },
          { title: '乗降車場所管理', link: '/administration/bus_stops/' },
          { title: '道表示登録', link: '/administration/roads/' }
        ]
      }
    ])

    const isAdmin = computed(() => {
      return context.root.$auth.user ? context.root.$auth.user.is_admin : false
    })

    const adminMenuItems = ref<NavigationItem>({
      title: '管理者機能',
      icon: 'mdi-wrench',
      items: [
        {
          title: '車両管理',
          link: '/administration/buses/',
          role: 'All'
        },
        {
          title: '発着所管理',
          link: '/system/depots/',
          role: 'All'
        },
        {
          title: '運行便管理',
          link: '/system/availabilities/',
          role: 'All'
        },
        {
          title: 'オペレーター管理',
          link: '/system/operators/',
          role: 'All'
        },
        {
          title: 'キオスク管理',
          link: '/system/agents/',
          role: 'All'
        },
        {
          title: '統計',
          link: '/system/statistics/',
          role: 'All'
        }
      ]
    })

    const fileredAdminMenuItems = computed(() => {
      return adminMenuItems.value.items.filter((item) =>
        context.root.$auth.user.username === 'root'
          ? ['All'].includes(item.role)
          : ['OnlyAdmin'].includes(item.role) || ['All'].includes(item.role)
      )
    })

    const logout = async () => {
      await context.root.$store
        .dispatch('setOperatorAvailability', {
          available: false
        })
        .catch(console.error)
      await context.root.$auth.logout().catch(console.error)
      await firebaseApp
        .auth()
        .signOut()
        .catch(console.error)
      context.root.$router.push('/login/')
    }

    return {
      isLoggedIn,
      user,
      navItems,
      isAdmin,
      adminMenuItems,
      fileredAdminMenuItems,
      logout
    }
  }
})
