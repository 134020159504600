


































































































































































/*
import * as fs from 'fs'
import * as path from 'path'
*/
import {
  createComponent,
  ref,
  computed,
  watch,
  SetupContext
} from '@vue/composition-api'
import moment from 'moment'
import { Device } from 'twilio-client'

const TWILIO_TOKEN_URL =
  'https://asia-northeast1-cti-ondemand-255210.cloudfunctions.net/getToken'
const TWILIO_RECORDON_URL =
  'https://asia-northeast1-cti-ondemand-255210.cloudfunctions.net/recordOn'
const TWILIO_DELETEVOICE_URL =
  'https://asia-northeast1-cti-ondemand-255210.cloudfunctions.net/deleteVoiceRecord'
const TWILIO_DOWNLOADVOICE_URL =
  'https://asia-northeast1-cti-ondemand-255210.cloudfunctions.net/downloadVoiceRecord'
const TWILIO_QUEUE_URL =
  'https://asia-northeast1-cti-ondemand-255210.cloudfunctions.net/queueCall'
const TWILIO_DISABLE_URL =
  'https://asia-northeast1-cti-ondemand-255210.cloudfunctions.net/disableClient'

export default createComponent({
  setup(_props: any, context: SetupContext) {
    let device: any
    // To ignore phone call while initializing
    context.root.$store
      .dispatch('setOperatorAvailability', {
        available: false
      })
      .then((id) => {
        return context.root.$axios.$get(`${TWILIO_TOKEN_URL}?id=${id}`)
      })
      .then((data) => {
        return new Promise((resolve: any, reject) => {
          device = new Device(data.token, {
            // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
            // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
            codecPreferences: ['opus', 'pcmu'],
            // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
            // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
            // a second time and sending the tone twice. This will be default in 2.0.
            fakeLocalDTMF: true,
            // Use `enableRingingState` to enable the device to emit the `ringing`
            // state. The TwiML backend also needs to have the attribute
            // `answerOnBridge` also set to true in the `Dial` verb. This option
            // changes the behavior of the SDK to consider a call `ringing` starting
            // from the connection to the TwiML backend to when the recipient of
            // the `Dial` verb answers.
            enableRingingState: true
          })

          device.on('ready', () => {
            console.log('Twilio.Device Ready!')
            resolve()
          })

          device.on('error', (error: any) => {
            console.log('Twilio.Device Error: ' + error.message)
            if (error.code === 31205 && context.root.$store.state.auth.user) {
              context.root.$axios
                .$get(
                  `${TWILIO_TOKEN_URL}?id=${context.root.$store.state.auth.user.id}`
                )
                .then((data) => {
                  device.updateToken(data.token)
                })
            } else {
              reject(error)
            }
          })

          device.on('connect', (_conn: any) => {
            console.log('Successfully established call!')
            twilioConnection.value = _conn
          })

          device.on('disconnect', (_conn: any) => {
            console.log('Call ended.')
            if (twilioConnection.value) {
              saveConnection.value = twilioConnection.value
            }
            isIncoming.value = false
            twilioConnection.value = false
            // 2021-12-14 着信時、フィルタ機能が正しく動作しない場合がある不具合の対応 - start
            context.root.$store.commit('updatePhoneNumber', {
              phoneNumber: ''
            })

            setTimeout(() => {
              context.root.$store.commit('updatePhoneNumber', {
                phoneNumber: phoneNumber.value
              })
            }, 100)
            // 2021-12-14 着信時、フィルタ機能が正しく動作しない場合がある不具合の対応 - end
            if (isRecording.value) {
              isRecording.value = false
              dialog.value = true
            } else {
              phoneNumber.value = ''
            }
          })

          device.on('incoming', (conn: any) => {
            console.log('Incoming connection from ' + conn.parameters.From)
            isIncoming.value = true
            twilioConnection.value = conn
            phoneNumber.value = conn.parameters.From.replace('+81', '0')
          })

          device.on('cancel', (_conn: any) => {
            isIncoming.value = false
            twilioConnection.value = false
          })
        })
      })
      .then(() => {
        // Check remaining queue
        return context.root.$store.dispatch('getOperator')
      })
      .then((data) => {
        if (data.queued && data.callSid) {
          isQueued.value = true
        } else {
          return context.root.$store.dispatch('setOperatorAvailability', {
            available: true
          })
        }
      })
      .catch(function(err) {
        console.log(err)
      })

    const twilioConnection: any = ref(false)
    const saveConnection: any = ref(false)
    const callingTime = ref<number>(0)
    const callingTimeStr = computed(() => {
      const minute = Math.floor(callingTime.value / 60)
      const second = `0${callingTime.value % 60}`
      return `${minute}:${second.slice(-2)}`
    })
    const isIncoming = ref<boolean>(false)
    const isTalking = computed<boolean>(() => {
      return !isIncoming.value && twilioConnection.value
    })
    const phoneNumber = ref<string>('')
    const savePhoneNumber = ref<string>('')
    const isOutgoingCall = ref<boolean>(false)
    const isInvalidPhoneNumber = computed(() => {
      const number = `+81${phoneNumber.value
        .replace(/[^\d]/g, '')
        .replace(/^0/, '')}`
      return (
        !/^[\d+\-() ]+$/.test(phoneNumber.value) ||
        (number.length !== 13 && number.length !== 12)
      )
    })
    watch(phoneNumber, () => {
      if (!phoneNumber.value) {
        isOutgoingCall.value = false
      }
    })

    // 電話回線接続
    const connect = () => {
      if (isIncoming.value && twilioConnection.value) {
        isIncoming.value = false
        twilioConnection.value.accept()
        context.root.$router.push({
          path: '/administration/users/'
        })
        // 2021-12-14 着信時、フィルタ機能が正しく動作しない場合がある不具合の対応 - start
        context.root.$store.commit('updatePhoneNumber', {
          phoneNumber: ''
        })
        setTimeout(() => {
          context.root.$store.commit('updatePhoneNumber', {
            phoneNumber: phoneNumber.value
          })
        }, 100)
        // 2021-12-14 着信時、フィルタ機能が正しく動作しない場合がある不具合の対応 - end
      } else if (/^[\d+\-() ]+$/.test(phoneNumber.value) && device) {
        const realPhoneNumber = `+81${phoneNumber.value
          .replace(/[^\d]/g, '')
          .replace(/^0/, '')}`
        const outgoingConnection = device.connect({ To: realPhoneNumber })
        outgoingConnection.on('ringing', () => {
          console.log(`Ringing to ${realPhoneNumber} ...`)
        })
      } else {
        console.log('Something wrong')
      }

      isRecording.value = false
      if (isAvailable.value) {
        twilioConnection.value = true
      }
    }

    // 電話回線切断
    const disconnect = () => {
      saveConnection.value = twilioConnection.value
      if (twilioConnection.value) {
        twilioConnection.value.reject()
        twilioConnection.value = false
        isIncoming.value = false
      }
      if (device) {
        device.disconnectAll()
        twilioConnection.value = false
        isIncoming.value = false
      }
      if (isRecording.value) {
        isRecording.value = false
        dialog.value = true
      }
    }

    const isQueued = ref<boolean>(false)
    const isQueuing = ref<boolean>(false)
    const addQueue = () => {
      savePhoneNumber.value = phoneNumber.value
      const callSid = twilioConnection.value.parameters.CallSid
      const id = context.root.$auth.user.id
      isQueuing.value = true
      context.root.$axios
        .get(`${TWILIO_QUEUE_URL}?callSid=${callSid}&id=${id}`)
        .then(() => {
          return Promise.resolve()
        })
        .then(() => {
          // ignore new connecting
          context.root.$store.dispatch('setOperatorAvailability', {
            available: false
          })
          isQueued.value = true
          isQueuing.value = false
        })
        .catch((err) => {
          isQueuing.value = false
          console.log(err)
        })
    }
    const resumeQueue = () => {
      const id = context.root.$auth.user.id
      const outgoingConnection = device.connect({
        To: `queue_operator_${id}`,
        queue: true,
        id
      })
      outgoingConnection.on('ringing', () => {
        console.log(`Ringing to queue ...`)
      })
      phoneNumber.value = savePhoneNumber.value
      isQueued.value = false
    }

    const isAvailable = ref<boolean>(true)
    // 有効切り替え
    const toggleAvailability = () => {
      context.root.$store
        .dispatch('setOperatorAvailability', {
          available: !isAvailable.value
        })
        .then(() => {
          isAvailable.value = !isAvailable.value
        })
    }

    const isRecording = ref<boolean>(false)
    const startRecordEnable = ref<boolean>(true)
    const recordInfo = ref('')
    // 録音開始
    const startRecording = () => {
      startRecordEnable.value = false
      const callSid = twilioConnection.value.parameters.CallSid
      isRecording.value = false
      context.root.$axios
        .get(`${TWILIO_RECORDON_URL}?callSid=${callSid}`)
        .then((response) => {
          isRecording.value = true
          console.log('=== recording start success ===')
          console.log(response.data)
          recordInfo.value = response.data.sid
          startRecordEnable.value = true
        })
        .catch((err) => {
          isRecording.value = false
          console.log('*** recording start error ***')
          console.log(err)
          startRecordEnable.value = true
        })
    }

    const dialog = ref<boolean>(false)
    const saveDialog = ref<boolean>(false)
    const voiceName = ref('')
    const isInvalidVoiceName = computed(() => {
      const val = voiceName.value
      if (!val) {
        return true
      }
      return !/^[^.]*$/.test(val)
    })
    const voiceRule = (val: string) => {
      if (!val) {
        return true
      }
      return /^[^.]*$/.test(val) || '拡張子は指定しません'
    }

    // 音声録音保存準備
    const prepareDownload = () => {
      const id = context.root.$auth.user.id
      const sdt = moment().format('YYYY_MM_DD_HH_mm')
      voiceName.value = sdt + '_' + id + '_' + phoneNumber.value
      dialog.value = false
      saveDialog.value = true
      phoneNumber.value = ''
    }

    const cancelEnable = ref<boolean>(true)
    // 音声録音ダウンロードキャンセル
    const cancelDownload = () => {
      execEnable.value = false
      cancelEnable.value = false
      const callSid = saveConnection.value.parameters.CallSid
      const recordSid = recordInfo.value
      if (isRecording) {
        context.root.$axios
          .get(
            `${TWILIO_DELETEVOICE_URL}?callSid=${callSid}&recSid=${recordSid}`
          )
          .then((response) => {
            isRecording.value = false
            dialog.value = false
            saveDialog.value = false
            console.log('=== cancel download success ===')
            console.log(response.data)
            execEnable.value = true
            cancelEnable.value = true
          })
          .catch((err) => {
            isRecording.value = false
            dialog.value = false
            saveDialog.value = false
            console.log('*** cancel download error ***')
            console.log(err)
            execEnable.value = true
            cancelEnable.value = true
          })
        saveConnection.value = false
        phoneNumber.value = ''
      }
    }

    const execEnable = ref<boolean>(true)
    // 音声録音ダウンロード実行
    const execDownload = () => {
      execEnable.value = false
      const callSid = saveConnection.value.parameters.CallSid
      const recordSid = recordInfo.value
      if (isRecording) {
        context.root.$axios
          .$get(
            `${TWILIO_DOWNLOADVOICE_URL}?callSid=${callSid}&recSid=${recordSid}`,
            {
              responseType: 'blob'
            }
          )
          .then((response) => {
            console.log('=== exec download success ===')
            const blob = new Blob([response], {
              type: 'audio/mpeg'
            })
            const fileName = voiceName.value + '.mp3'
            if (window.navigator.msSaveOrOpenBlob) {
              // for IE, Edge
              window.navigator.msSaveOrOpenBlob(blob, fileName)
            } else {
              // for Chrome , etc...
              const url = window.URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              window.URL.revokeObjectURL(url)
              if (link.parentNode != null) {
                link.parentNode.removeChild(link)
              }
            }
            saveDialog.value = false
            cancelDownload()
            execEnable.value = true
          })
          .catch((err) => {
            console.log('*** exec download error ***')
            console.log(err)
            saveConnection.value = false
            isRecording.value = false
            saveDialog.value = false
            execEnable.value = true
          })
      }
    }

    context.root.$nuxt.$on('callTo', (numberTo: string) => {
      phoneNumber.value = numberTo
    })

    watch(twilioConnection, () => {
      if (isQueued.value || isQueuing.value) {
        return
      }
      context.root.$store.dispatch('setOperatorAvailability', {
        available: !twilioConnection.value
      })
    })

    // let serviceWorker = navigator.serviceWorker.controller
    let serviceWorker: any = false
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker
        .register('/logout-sw.js', { scope: '/' })
        .then((registration) => {
          if (registration.installing) {
            serviceWorker = registration.installing
          } else if (registration.waiting) {
            serviceWorker = registration.waiting
          } else if (registration.active) {
            serviceWorker = registration.active
          }
        })
    }

    window.addEventListener('beforeunload', () => {
      if (serviceWorker) {
        serviceWorker.postMessage({
          user: context.root.$auth.user,
          url: TWILIO_DISABLE_URL
        })
      }
    })

    return {
      callingTime,
      callingTimeStr,
      isIncoming,
      isTalking,
      phoneNumber,
      isOutgoingCall,
      twilioConnection,
      isInvalidPhoneNumber,
      isAvailable,
      isRecording,
      isQueuing,
      isQueued,
      disconnect,
      connect,
      addQueue,
      resumeQueue,
      toggleAvailability,
      startRecordEnable,
      startRecording,
      dialog,
      saveDialog,
      voiceName,
      isInvalidVoiceName,
      voiceRule,
      prepareDownload,
      cancelEnable,
      cancelDownload,
      execEnable,
      execDownload
    }
  },
  auth: false
})
