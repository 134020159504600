import { Route } from './route'

export class Driver {
  public id!: number
  public first_name!: string
  public last_kana!: string
  public driving_licence_number!: string
  public phone_number!: string
  public sex: string = ''
  public last_name!: string
  public username: string = ''
  public first_kana!: string
  public email: string = ''
  public birthday!: string
  public routes!: Route[]
  public address: string = ''
  public location_name: string = ''
  public password!: string

  constructor(props: {
    id: number
    first_name: string
    last_kana: string
    driving_licence_number: string
    phone_number: string
    sex: string
    last_name: string
    username: string
    first_kana: string
    email: string
    birthday: string
    location_name: string
    address: string
    password: string
  }) {
    Object.assign(this, props)
  }

  /**
   * get all of routes data
   * @param $axios
   * @param event
   */
  public getRoutes($axios: any, event: (routes: Route[]) => void) {
    if (this.routes) {
      event(this.routes)
    } else {
      this.updateRoutes($axios, (routes) => {
        this.routes = routes
        event(routes)
      })
    }
  }

  /**
   * update all of routes data
   * @param $axios
   * @param event
   */
  public updateRoutes($axios: any, event: (routes: Route[]) => void) {
    const target = this.id
    $axios.$get('/routes/').then((response: any) => {
      event(
        response
          .filter((route: Route) => route.driver_id === target)
          .sort((a: Route, b: Route) => {
            const date_a: Date =
              typeof a.stop_time === 'string'
                ? new Date(a.stop_time)
                : a.stop_time
            const date_b: Date =
              typeof b.stop_time === 'string'
                ? new Date(b.stop_time)
                : b.stop_time
            return date_a > date_b ? -1 : date_a < date_b ? 1 : 0
          })
      )
    })
  }
}

export const getDrivers = async ($axios: any) => {
  const drivers: Driver[] = []
  const response = await $axios.$get('/drivers/')
  response.forEach((value: any) => {
    drivers.push(new Driver(value))
  })
  return drivers
}

export const updateDriver = (_$axios: any, driver: Driver) => {
  if (driver.id === -1) {
    const data: any = {
      first_name: driver.first_name,
      last_name: driver.last_name,
      first_kana: driver.first_kana,
      last_kana: driver.last_kana,
      birthday: driver.birthday,
      phone_number: driver.phone_number,
      sex: driver.sex,
      driving_licence_number: driver.driving_licence_number,
      password: driver.password
    }
    const params = new URLSearchParams(data)
    _$axios.$post('/drivers/', params)
  } else {
    const data = [
      {
        op: 'replace',
        path: '/first_kana',
        value: driver.first_kana
      },
      {
        op: 'replace',
        path: '/last_kana',
        value: driver.last_kana
      },
      {
        op: 'replace',
        path: '/birthday',
        value: driver.birthday
      },
      {
        op: 'replace',
        path: '/phone_number',
        value: driver.phone_number
      },
      {
        op: 'replace',
        path: '/sex',
        value: driver.sex
      },
      // {
      //   op: 'replace',
      //   path: '/home_location_name',
      //   value: driver.
      // },
      {
        op: 'replace',
        path: '/driving_licence_number',
        value: driver.driving_licence_number
      },
      {
        op: 'replace',
        path: '/location_name',
        value: driver.location_name
      },
      {
        op: 'replace',
        path: '/address',
        value: driver.address
      },
      {
        op: 'replace',
        path: '/email',
        value: driver.email
      }
      // TODO: add reset password method
      // {
      //   op: 'test',
      //   path: '/current_password',
      //   value: props.user.password
      // },
      // {
      //   op: 'replace',
      //   path: '/password',
      //   value: password.value
      // }
    ]
    _$axios.$patch(`/drivers/${driver.id}`, data)
  }
  return Promise.resolve()
}

export const searchByName = (
  $axios: any,
  keyword: string
): Promise<Driver[]> => {
  return $axios.$get(
    `/drivers/?filter=first_name like ${keyword} or last_name like ${keyword} or first_kana like ${keyword} or first_kana like ${keyword}`
  )
}

export const getById = ($axios: any, id: number): Promise<Driver> => {
  return $axios.$get(`/drivers/${id}`)
}

export const remove = ($axios: any, driverId: number) => {
  return $axios.$delete(`/drivers/${driverId}`)
}
