import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyCS-4OoVXO5dL-P6OV1Ue8x8ocbBQ1n6kg',
  authDomain: 'cti-ondemand-255210.firebaseapp.com',
  databaseURL: 'https://cti-ondemand-255210.firebaseio.com',
  projectId: 'cti-ondemand-255210',
  storageBucket: 'cti-ondemand-255210.appspot.com',
  messagingSenderId: '271897390108',
  appId: '1:271897390108:web:23154590783241e11a83d9'
})
export const firestore = firebaseApp.firestore()

const { Timestamp } = firebase.firestore
export { Timestamp }
