





























































import { computed, createComponent, ref, watch } from '@vue/composition-api'
import { getByDriver as getVehicleByDriver } from '@/models/vehicle'
import { Driver, getById as getDriverById } from '~/models/driver'

type Message = {
  id: string
  from: string
  to: string[]
  message: string
  created_at: any
  originalMessage: any
  driver?: Driver
}

export default createComponent({
  setup(_props, context) {
    const messages = computed(() => {
      return context.root.$store.state.message.messages
    })
    const readMessages = computed(() => {
      return context.root.$store.state.message.readMessages
    })
    const newMessages = ref([])
    const updateMessages = async () => {
      const sortedMessage = messages.value
        .filter(
          (m: Message) =>
            m.to.includes('operator') &&
            !readMessages.value.find((cm: any) => cm.id === m.id)
        )
        .sort(
          (a: Message, b: Message) =>
            a.created_at.seconds - b.created_at.seconds
        )
      const tmp: any = []
      for (let i = 0; i < sortedMessage.length; i++) {
        const m = sortedMessage[i]
        if (m.from.startsWith('driver') && m.message) {
          m.originalMessage = messages.value.find(
            (cm: Message) => cm.id === m.message
          )
          if (!m.originalMessage) {
            m.originalMessage = await context.root.$store.dispatch(
              'message/getMessageById',
              { id: m.message }
            )
          }
        }
        if (m.from.startsWith('driver') && i === 0) {
          m.driver = await getDriverById(
            context.root.$axios,
            Number(m.from.replace('driver', ''))
          ).catch(() => undefined)
        }
        tmp.push(m)
      }
      newMessages.value = tmp
    }
    watch(messages, updateMessages)
    watch(readMessages, updateMessages)
    const markAsRead = (message: any) => {
      newMessages.value = []
      context.root.$store.commit('message/markAsReadMessage', { message })
    }

    const dialog = ref<boolean>(false)
    const getDriverName = (message: Message) => {
      if (message.driver) {
        const driver = message.driver
        return `${driver.last_name}${driver.first_name}`
      } else {
        return message.from
      }
    }
    const checkPassenger = async (message: Message) => {
      if (message.driver) {
        try {
          const vehicle = await getVehicleByDriver(
            context.root.$axios,
            message.driver.id
          )
          if (vehicle) {
            context.root.$store.commit('setVehicleId', {
              vehicleId: vehicle.id
            })
          } else {
            dialog.value = true
          }
          context.root.$router.push('/overview/buses/')
        } catch (e) {
          console.error(e)
          dialog.value = true
        }
      }
    }
    const callToDriver = (message: Message) => {
      if (message.driver && message.driver.phone_number) {
        context.root.$nuxt.$emit('callTo', message.driver.phone_number)
      }
    }
    context.root.$store.dispatch('message/init')

    return {
      newMessages,
      markAsRead,
      dialog,
      getDriverName,
      checkPassenger,
      callToDriver
    }
  }
})
